import { Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LoadingButton } from 'components/button';
import { Password, SmarterTextField } from 'components/input';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postCreateNewPassword, postVerifyOTP } from '../../apis';
import { UserContext } from '../../context/UserContext';
import Validation from './Validation';
import { isPasswordValid } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '20px 60px',
    textAlign: 'center',
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: '30px',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#fff',
  },
  activationBox: {
    padding: `${theme.spacing(4.8)}px ${theme.spacing(4)}px`,
    background: '#fff',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
    maxWidth: theme.spacing(40),
    margin: 'auto',
    width: '100%',
  },
  pageTitle: {
    fontSize: theme.spacing(2.6),
    fontWeight: 700,
    display: 'block',
    textAlign: 'center',
  },
  pageSubtitle: {
    fontWeight: 400,
    display: 'block',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  textLabel: {
    display: 'block',
    marginBottom: theme.spacing(0.8),
  },
  submitButton: {
    backgroundColor: '#000',
    color: '#fff',
    display: 'block',
    width: '100%',
    height: theme.spacing(6.4),
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
  },
  termCondition: {
    margin: '1rem 0 2rem 0',
  },
  termConditionLink: {
    color: '#000000',
    fontWeight: 600,
  },
  divider: {
    width: '100%',
    height: '20px',
  },
  checkbox: {
    accentColor: '#F89944',
  },
}));
export const Activation = () => {
  const classes = useStyles();
  const { setActivationSuccess, passwordConfiguration, passwordRules } = useContext(UserContext);
  const [touched, setTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({});

  const { search } = useLocation();
  const navigate = useNavigate();
  const queryString = new URLSearchParams(search);
  const [password, setPassword] = useState({
    value: '',
    type: 'password',
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState({
    value: '',
    type: 'password',
  });

  const [agree, setAgree] = useState(false);

  const onToggleVisibilityHandler = (isConfirmationPassword) => {
    if (isConfirmationPassword) {
      setPasswordConfirmation({
        ...password,
        type: passwordConfirmation.type === 'text' ? 'password' : 'text',
      });
    } else {
      setPassword({
        ...password,
        type: password.type === 'text' ? 'password' : 'text',
      });
    }
  };


  const handleActivateAccount = (event) => {
    setIsServerError(false);
    setIsLoading(true);
    event.preventDefault();
    const payload = {
      email: queryString.get('email'),
      new_password: password.value,
    };
    postVerifyOTP({
      username: queryString.get('email'),
      verification_code: queryString.get('token'),
      purpose: 'activate-password',
    })
      .then(() => {
        postCreateNewPassword(payload)
          .then((data) => {
            setActivationSuccess(true);
            navigate('/login');
          })
          .catch((err) => {
            setIsServerError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsServerError(true);
        setIsLoading(false);
      });
  };

 
  let isButtonDisabled = false;
  for (const err in passwordErrors) {
    if(!passwordErrors[err]) {
      isButtonDisabled = true
    }
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.activationBox}>
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          Activate Your Account
        </Typography>
        <Typography component="p" variant="subtitle1" className={classes.pageSubtitle}>
          Please create a new password for your account
        </Typography>
        <form onSubmit={handleActivateAccount}>
          <div>
            <label className={classes.textLabel} htmlFor="user-app_login_email">
              Email
            </label>
            <SmarterTextField
              value={queryString.get('email')}
              disabled
              inputProps={{ 'data-testid': 'user-app_activation_email' }}
            />
          </div>
          <div className={classes.divider} />
          <Password
            label="New Password"
            type={password.type}
            value={password.value}
            onChange={(value) => { 
              setPassword((prev) => ({ ...prev, value }))
              setPasswordErrors(isPasswordValid(value, passwordRules))
            }}
            onToggle={() => onToggleVisibilityHandler(false)}
            placeholder="Enter Password"
            error={touched && password.value !== passwordConfirmation.value}
            id="user_app-login-password"
          />
          <div className={classes.divider} />
          <Password
            label="Re-enter Password"
            type={passwordConfirmation.type}
            value={passwordConfirmation.value}
            onChange={(value) => {
              if (!touched) {
                setTouched(true);
              }
              setPasswordConfirmation((prev) => ({ ...prev, value }));
            }}
            onToggle={() => onToggleVisibilityHandler(true)}
            placeholder="Enter Password"
            id="user_app-login-password_confirmation"
            error={touched && password.value !== passwordConfirmation.value}
            helperText={
              touched && password.value !== passwordConfirmation.value &&
              'The password confirmation does not match.'
            }
          />
          <div className={classes.termCondition}>
            <input
              data-testid="agree_term_condition"
              className={classes.checkbox}
              type="checkbox"
              value={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <label for="vehicle1">
              I agree to the{' '}
              <a
                className={classes.termConditionLink}
                href="https://platform.smarterhealth.sg/user/tnc"
              >
                Terms & Conditions
              </a>
            </label>
          </div>
          <Validation passwordConfiguration={passwordConfiguration} errors={passwordErrors} />
          <LoadingButton
            classes={classes.submitButton}
            disabled={!agree || isButtonDisabled}
            text="Activate"
          />
        </form>
        {
          <Dialog open={isServerError}>
            <div className={classes.dialogContainer}>
              <Typography component="h1" className={classes.dialogTitle}>
                Error
              </Typography>
              <Typography component="p" className={classes.dialogText}>
                Unable to proceed due to connection timeout.
              </Typography>
              <LoadingButton
                classes={classes.submitButton}
                loading={isLoading}
                onClick={handleActivateAccount}
                text="Try Again"
              />
            </div>
          </Dialog>
        }
      </div>
    </div>
  );
};
